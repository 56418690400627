import {FC} from 'react';
import CountryConfiguration from './CountryConfiguration';
import {Alpha3Code} from 'i18n-iso-countries';
import ViewTitle from 'viewComponents/ViewTitle/ViewTitle';
import TabView, {TabProps} from 'components/TabView/TabView';
import LeaveParamsCreateCountryForm from 'viewComponents/LeaveParamsCreateCountryForm/LeaveParamsCreateCountryForm';
import AddIcon from '@mui/icons-material/Add';
import GqlQueryController from 'controllers/GqlQueryController/GqlQueryController';
import {GET_CONFIGURATIONS} from 'graphql/absences/Querys';
import {ParamsConfigurationProps} from 'models/configuration';

const ParamsConfiguration: FC = () => {
  return (
    <div className="Dashboard w-full flex flex-col flex-wrap pt-12">
      <div className="flex mb-6">
        <ViewTitle disableBackButton>Gestión de parámetros</ViewTitle>
      </div>
      <GqlQueryController
        initialState={[] as ParamsConfigurationProps[]}
        QUERY={GET_CONFIGURATIONS}
        rootField={'getConfigurations'}
      >
        {({data: configurations, fetch}) => {
          const tabs: TabProps[] = configurations.map(
            (configuration: ParamsConfigurationProps) => ({
              label: configuration.country,
              view: (
                <CountryConfiguration
                  country={configuration.country as Alpha3Code}
                  holidays={configuration.holidays}
                  inabilities={configuration.inabilities}
                  vacations={configuration.vacations}
                  fetch={fetch}
                />
              ),
            })
          );

          tabs.push({
            label: '',
            props: {
              icon: <AddIcon />,
              iconPosition: 'start',
            },
            view: <LeaveParamsCreateCountryForm onSubmit={fetch} />,
          });

          return <TabView tabs={tabs} />;
        }}
      </GqlQueryController>
    </div>
  );
};

export default ParamsConfiguration;
