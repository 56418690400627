import React, {useEffect, useRef, useState} from 'react';
import {
  DateCalendarSlotProps,
  DateCalendar as MUIDateCalendar,
} from '@mui/x-date-pickers';
import {
  Box,
  FormControl,
  IconButton,
  InputBase,
  Paper,
  Popover,
  Typography,
} from '@mui/material';
import {format as formatDate} from 'date-fns';
import DateDayPicker from './DateDayPicker';
import {eachDayOfInterval} from 'date-fns';
import './DateRangePicker.scss';
import CalendarToday from '@mui/icons-material/CalendarToday';
import {PickerSelectionState} from '@mui/x-date-pickers/internals';

interface DateRange {
  startDate: Date | null;
  endDate: Date | null;
}

export interface DateRangePickerProps {
  label: string;
  format?: string;
  holidays?: Date[];
  weekendsDisabled?: boolean;
  onChangeYear?: (year: number) => void;
  onChangeDates?: (from: Date | null, to: Date | null) => void;
  defaultValue?: DateRange | Date;
  selectionType?: 'single' | 'range';
  minDate?: Date;
  maxDate?: Date;
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({
  label,
  format = 'dd/MM/yyyy',
  holidays = [],
  weekendsDisabled = false,
  onChangeYear,
  onChangeDates,
  defaultValue,
  selectionType = 'range',
  minDate,
  maxDate,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [dateText, setDateText] = useState<string>('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(textFieldRef.current);
  };
  const isOpen = Boolean(anchorEl);
  const id = isOpen ? 'calendar-popover' : undefined;

  const placeHolderMask = 'DD/MM/YYYY';
  const defaultPlaceHolder =
    selectionType === 'range'
      ? `${placeHolderMask} - ${placeHolderMask}`
      : placeHolderMask;

  const dateToString = () => {
    if (selectionType === 'range' && (startDate === null || endDate === null)) {
      return null;
    }
    const start = startDate ? formatDate(startDate, format) : placeHolderMask;
    const end = endDate ? formatDate(endDate, format) : placeHolderMask;
    return selectionType === 'range' ? `${start} - ${end}` : start;
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const range = dateToString();
    setDateText(range || '');
    onChangeDates && onChangeDates(startDate, endDate);
  }, [startDate, endDate]);

  useEffect(() => {
    if (isOpen) {
      if (startDate === null || endDate === null) {
        cleanDate();
      }
    }
  }, [isOpen]);

  const cleanDate = () => {
    setEndDate(null);
    setStartDate(null);
  };

  const onSetDate = (
    date: Date,
    selectionState: PickerSelectionState | undefined
  ) => {
    if (selectionType === 'single') {
      setStartDate(date);
      setEndDate(date);
      if (selectionState !== 'partial') {
        handleClose();
      }
      return;
    }
    if (startDate !== null && endDate === null) {
      if (date < startDate) {
        setEndDate(startDate);
        setStartDate(date);
      } else {
        setEndDate(date);
      }
      if (selectionState !== 'partial') {
        handleClose();
      }
    } else {
      setEndDate(null);
      setStartDate(date);
    }
  };

  const textFieldRef = useRef(null);

  const getRange: () => Date[] = () => {
    if (startDate === null || endDate === null) {
      return [];
    }
    return eachDayOfInterval({start: startDate, end: endDate});
  };

  const day = {
    weekendDisabled: weekendsDisabled,
    startDate: startDate,
    endDate: endDate,
    highlightedDays: getRange(),
    disabledDays: holidays,
    minDate: minDate,
    maxDate: maxDate,
  };

  const dataDate: DateCalendarSlotProps<Date> = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    day: day as any,
  };

  useEffect(() => {
    const defaultRangeValue = defaultValue as DateRange;
    if (defaultRangeValue?.startDate || defaultRangeValue?.startDate === null) {
      setStartDate(defaultRangeValue.startDate);
      setEndDate(defaultRangeValue.endDate);
    } else if (defaultValue) {
      setStartDate(defaultValue as Date);
    }
  }, [defaultValue]);

  return (
    <FormControl className="DateRangePicker" fullWidth>
      <Typography variant="subtitle2">{label}</Typography>
      <Paper
        sx={{
          p: '2px 4px',
          display: 'flex',
          alignItems: 'center',
          border: '1px solid #ced4da',
          borderRadius: '12px',
          height: 56,
          justifyContent: 'space-between',
          boxShadow: 'inherit',
        }}
      >
        <InputBase
          data-testid="date-range-picker"
          className="w-full p-2"
          readOnly={true}
          onClick={handleClick}
          placeholder={defaultPlaceHolder}
          value={dateText}
          ref={textFieldRef}
        />
        <IconButton onClick={handleClick}>
          <CalendarToday color="primary" />
        </IconButton>
        <Popover
          data-testid="calendar-popover"
          id={id}
          open={isOpen}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Box>
            <MUIDateCalendar
              data-testid="calendar-card"
              onYearChange={date => {
                onChangeYear && onChangeYear(date.getFullYear());
              }}
              onMonthChange={date => {
                onChangeYear && onChangeYear(date.getFullYear());
              }}
              slots={{
                day: DateDayPicker,
              }}
              slotProps={dataDate}
              onChange={onSetDate}
              minDate={minDate}
              maxDate={maxDate}
            />
          </Box>
        </Popover>
      </Paper>
    </FormControl>
  );
};

export default DateRangePicker;
