/* eslint-disable @typescript-eslint/no-explicit-any*/
import {FC} from 'react';
import Modal, {ModalProps} from 'components/Modal/Modal';
import {Button} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {
  DocumentNode,
  OperationVariables,
  TypedDocumentNode,
} from '@apollo/client';
import './DeleteParamModal.scss';
import GqlFormController from 'controllers/GqlFormController/GqlFormController';

interface ParamItem {
  label: string;
  value: string | unknown;
}

interface DeleteParamModalProps
  extends Omit<ModalProps, 'children' | 'title' | 'description' | 'onSubmit'> {
  children?: Children;
  values: ParamItem[];
  mutation: DocumentNode | TypedDocumentNode<any, OperationVariables>;
  variablesMapper: (
    values: GQLValue,
    args?: Map<string, GQLValue>
  ) => OperationVariables;
  onSubmit: () => void;
}

const DeleteParamModal: FC<DeleteParamModalProps> = ({
  onClose,
  onSubmit,
  values,
  mutation: MUTATION,
  variablesMapper,
  ...other
}) => {
  const {t} = useTranslation();
  values = Object.entries(values).map(([label, value]) => ({
    label: t(`leaves.DeleteParamModal.${label}`),
    value: (value as any)?.description || value,
  }));

  const handleOnSubmit = (mutationPromise: any) => {
    mutationPromise.then(() => {
      onSubmit();
      onClose && onClose({}, 'backdropClick');
    });
  };

  return (
    <Modal
      small
      className="DeleteParamModal"
      title={t('leaves.DeleteParamModal.title')}
      description={t('leaves.DeleteParamModal.description')}
      onClose={onClose}
      {...other}
    >
      <GqlFormController
        className="flex flex-col"
        itemProps={{xs: 12}}
        mutation={MUTATION}
        variablesMapper={variablesMapper}
        onSubmit={handleOnSubmit}
        controls={values
          .filter(item => item.label.indexOf('__typename') < 0)
          .map(item => ({
            name: 'palowanName',
            type: 'label',
            props: {
              label: item.label,
              value: item.value,
            },
          }))}
      >
        {({onSubmit}: any) => (
          <div>
            <Button
              variant="outlined"
              onClick={event => onClose && onClose(event, 'backdropClick')}
            >
              {t('leaves.DeleteParamModal.cancel')}
            </Button>
            <Button onClick={onSubmit()} variant="contained">
              {t('leaves.DeleteParamModal.submit')}
            </Button>
          </div>
        )}
      </GqlFormController>
    </Modal>
  );
};

export default DeleteParamModal;
