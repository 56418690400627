/* eslint-disable @typescript-eslint/no-explicit-any */
import {FC, useContext} from 'react';
import {Catalog} from 'models/catalog';
import {LEAVE_STATUS_DRAFT, LeaveRequest} from 'models/leaveRequest';
import Modal from 'components/Modal/Modal';
import GqlFormController from 'controllers/GqlFormController/GqlFormController';
import {Button, ModalProps} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {UPDATE_LEAVE_REQUEST} from 'graphql/absences/Mutations';
import dayjs from 'dayjs';
import GqlQueryController from 'controllers/GqlQueryController/GqlQueryController';
import {GET_CATALOGS} from 'graphql/absences/Querys';
import LeaveRequestEvidenceController from 'controllers/LeaveRequestEvidenceController';
import {AssignationType} from 'models/assignationType';
import withFormViewModel from 'viewComponents/CreateLeaveRequestForm/formViewModel';
import {formatDate} from 'commons/dates';
import './LeaveRequestUpdateModal.scss';
import {END_AT_MORNING, START_AT_EVENING} from 'models/halfDay';
import {ADDITIONAL_LEAVE} from 'models/leaveRequestType';
import {authContext} from 'contextApi/context/auth';

interface LeaveRequestUpdateModalProps
  extends Omit<ModalProps, 'children' | 'onSubmit'> {
  children?: Children;
  leaveData?: LeaveRequest;
  onSubmit: (mutation: any, data: any) => Promise<any>;
}

const LeaveRequestUpdateModal: FC<LeaveRequestUpdateModalProps> = ({
  onClose,
  leaveData,
  onSubmit: formViewModelOnSubmitHandler,
  ...other
}) => {
  const {userDetails} = useContext(authContext);
  const defaultValues = {
    id: leaveData?.id,
    leaveRangeDate: {
      startDate: dayjs(leaveData?.startDate).toDate(),
      endDate: dayjs(leaveData?.endDate).toDate(),
      startCheck: leaveData?.scheduleOptions?.find(
        value => value === START_AT_EVENING
      ),
      endCheck: leaveData?.scheduleOptions?.find(
        value => value === END_AT_MORNING
      ),
    },
  };
  const {t} = useTranslation();

  const handleOnSubmit = (mutationPromise: any, data: any) => {
    formViewModelOnSubmitHandler(mutationPromise, data).finally(() => {
      window.location.reload();
    });
  };

  const variablesMapper = (formData: any) => {
    const scheduleOptions: string[] = [];
    formData.leaveRangeDate.startCheck &&
      scheduleOptions.push(START_AT_EVENING);
    formData.leaveRangeDate.endCheck && scheduleOptions.push(END_AT_MORNING);

    const request = {
      leaveRequest: {
        id: leaveData?.id,
        startDate: formatDate(formData.leaveRangeDate.startDate),
        endDate: formatDate(formData.leaveRangeDate.endDate),
        scheduleOptions,
        isDraft: leaveData?.status === LEAVE_STATUS_DRAFT,
      },
    };
    return request;
  };

  return (
    <Modal
      title={t('leaves.LeaveRequestUpdateModal.title')}
      description={t('leaves.LeaveRequestUpdateModal.description')}
      onClose={onClose}
      {...other}
    >
      <GqlQueryController
        QUERY={GET_CATALOGS}
        variables={{type: 'CHANGE_REASON', country: userDetails?.data?.country}}
        initialState={[] as Catalog[]}
        rootField="catalogs"
      >
        {({data: moditicationTypes}) => (
          <LeaveRequestEvidenceController
            assignationType={AssignationType.PROJECT}
            leaveType={leaveData?.typeOfLeave?.name}
          >
            {({evidenceFormControls}) => (
              <GqlFormController
                mutation={UPDATE_LEAVE_REQUEST}
                variablesMapper={variablesMapper}
                className="flex flex-col"
                itemProps={{xs: 12}}
                defaultValues={{...defaultValues}}
                onSubmit={handleOnSubmit}
                controls={[
                  {
                    name: 'typeOfLeave',
                    type: 'label',
                    props: {
                      label: t('leaves.LeaveRequestUpdateModal.typeOfLeave'),
                      value: leaveData?.typeOfLeave?.description,
                    },
                  },
                  {
                    name: 'reason',
                    type: 'select',
                    options: {
                      required: true,
                    },
                    props: {
                      label: 'Motivo de modificación',
                      placeholder: 'Selecciona una opción',
                      className: 'mt-2',
                      noSelection: t('common.choose_an_option'),
                      children: (moditicationTypes || []).map(
                        (option: any) => ({
                          key: option.id,
                          description: option.description,
                        })
                      ),
                    },
                  },
                  {
                    name: 'leaveRangeDate',
                    type: 'leaveDateRangePicker',
                    options: {
                      required: true,
                    },
                    props: {
                      labels: {
                        startDateLabel: t(
                          'leaves.MyLeaves.inputLabels.startDateLabel'
                        ),
                        endDateLabel: t(
                          'leaves.MyLeaves.inputLabels.endDateLabel'
                        ),
                        startCheckLabel: t(
                          'leaves.MyLeaves.inputLabels.startCheckLabel'
                        ),
                        endCheckLabel: t(
                          'leaves.MyLeaves.inputLabels.endCheckLabel'
                        ),
                        optionalStartCheckLabel: t(
                          'leaves.MyLeaves.inputLabels.optionalStartCheckLabel'
                        ),
                        optionalEndCheckLabel: t(
                          'leaves.MyLeaves.inputLabels.optionalEndCheckLabel'
                        ),
                      },
                      withChecks: !(
                        leaveData?.typeOfLeave.name === ADDITIONAL_LEAVE
                      ),
                    },
                  },
                  {
                    name: 'newEvidences',
                    type: 'label',
                    props: {
                      value: t('leaves.LeaveRequestUpdateModal.newEvidences'),
                    },
                  },
                  evidenceFormControls,
                ]}
              >
                {({onSubmit}: any) => (
                  <div>
                    <Button
                      variant="outlined"
                      onClick={event =>
                        onClose && onClose(event, 'backdropClick')
                      }
                    >
                      {t('leaves.LeaveRequestUpdateModal.exit')}
                    </Button>
                    <Button onClick={onSubmit()} variant="contained">
                      {t('leaves.LeaveRequestUpdateModal.submit')}
                    </Button>
                  </div>
                )}
              </GqlFormController>
            )}
          </LeaveRequestEvidenceController>
        )}
      </GqlQueryController>
    </Modal>
  );
};

export default withFormViewModel()(LeaveRequestUpdateModal);
