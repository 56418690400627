import {FC} from 'react';
import countries from 'i18n-iso-countries';
import BlankCard from 'components/BlankCard/BlankCard';
import TabView from 'components/TabView/TabView';
import VacationDaysParamsTable from 'viewComponents/VacationDaysParamsTable';
import HealthcareParamsTable from 'viewComponents/HealthcareParamsTable/HealthcareParamsTable';
import HolidaysParamsTable from 'viewComponents/HolidaysParamsTable/HolidaysParamsTable';
import {useTranslation} from 'react-i18next';
import {ParamsConfigurationProps} from 'models/configuration';

const CountryConfiguration: FC<ParamsConfigurationProps> = ({
  country,
  inabilities,
  vacations,
  holidays,
  fetch,
}) => {
  const {t} = useTranslation();

  const items = [
    {
      label: t('leaves.CountryConfiguration.holidays'),
      view: (
        <HolidaysParamsTable
          fetch={fetch}
          country={country}
          values={holidays}
        />
      ),
    },
    {
      label: t('leaves.CountryConfiguration.healthcare'),
      view: (
        <HealthcareParamsTable
          fetch={fetch}
          country={country}
          values={inabilities}
        />
      ),
    },
    {
      label: t('leaves.CountryConfiguration.vacationDays'),
      view: (
        <VacationDaysParamsTable
          fetch={fetch}
          country={country}
          values={vacations}
        />
      ),
    },
  ];
  return (
    <div className="mt-4">
      <BlankCard title={`Parámetros para ${countries.getName(country, 'es')}`}>
        <TabView tabs={items} />
      </BlankCard>
    </div>
  );
};

export default CountryConfiguration;
