/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import StickyHeadTable, {
  ColumnProps,
} from 'components/StickyHeadTable/StickyHeadTable';
import {useTranslation} from 'react-i18next';
import ModalController from 'controllers/ModalController';
import {Button, IconButton, Typography} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import {formatDate, parseDate} from 'commons/dates';
import {ConfigurationTableProps} from 'models/configuration';
import DeleteParamModal from 'viewComponents/DeleteParamModal/DeleteParamModal';
import {ADD_HOLIDAY, DELETE_HOLIDAY} from 'graphql/admin/Mutation';
import AddParamModal from 'viewComponents/AddParamModal/AddParamModal';
import './HolidaysParamsTable.scss';
import dayjs from 'dayjs';

const HolidaysParamsTable: React.FC<ConfigurationTableProps> = ({
  country,
  values,
  fetch,
}) => {
  const {t} = useTranslation();

  const renderDeleteController = (values: any) => (
    <ModalController
      trigger={({onToggleModal}) => (
        <IconButton onClick={onToggleModal}>
          <DeleteIcon />
        </IconButton>
      )}
      modal={({isModalOpen, onToggleModal}) => (
        <DeleteParamModal
          variablesMapper={() => {
            const date = parseDate(values.date || '');
            return {
              country,
              date: values.date,
              year: date.getFullYear(),
            };
          }}
          onSubmit={fetch}
          mutation={DELETE_HOLIDAY}
          values={values}
          onClose={onToggleModal}
          open={isModalOpen}
        />
      )}
    />
  );

  const columns: ColumnProps[] = [
    {
      id: 'name',
      label: t('leaves.HolidaysParamsTable.name'),
    },
    {
      id: 'date',
      label: t('leaves.HolidaysParamsTable.date'),
    },
    {
      id: 'id',
      label: t('leaves.HolidaysParamsTable.actions'),
      align: 'right',
      render: (_: string, values: any) => renderDeleteController(values),
    },
  ];

  return (
    <div
      data-testid="HolidaysParamsTable"
      className="HolidaysParamsTable w-full flex flex-col flex-wrap pt-6"
    >
      <div className="flex flex-row justify-end">
        <ModalController
          trigger={({onToggleModal}) => (
            <Button
              variant="outlined"
              onClick={() => {
                onToggleModal();
              }}
            >
              {t('leaves.HolidaysParamsTable.addButtonLabel')}
            </Button>
          )}
          modal={({isModalOpen, onToggleModal}) => (
            <AddParamModal
              title={t('leaves.HolidaysParamsTable.addModalTitle')}
              formProps={{
                controls: [
                  {
                    name: 'description',
                    options: {
                      required: true,
                    },
                    props: {
                      label: t('leaves.HolidaysParamsTable.name'),
                    },
                  },
                  {
                    name: 'date',
                    options: {
                      required: true,
                    },
                    type: 'date',
                    props: {
                      label: t('leaves.HolidaysParamsTable.date'),
                      selectionType: 'single',
                    },
                  },
                ],
                variablesMapper: (values: any) => {
                  const date = formatDate(values.date.startDate || '');
                  return {
                    ...values,
                    country,
                    date,
                    year: dayjs(date).year(),
                  };
                },
                onSubmit: () => {
                  fetch();
                  onToggleModal();
                },
                mutation: ADD_HOLIDAY,
              }}
              onClose={onToggleModal}
              open={isModalOpen}
            />
          )}
        />
      </div>
      {values.map((value: any) => (
        <>
          <Typography variant="h6" className="mb-4">
            {value.year}
          </Typography>
          <StickyHeadTable
            columns={columns}
            placeholder={t('common.empty_list')}
            hidePagination
            isLoading={false}
            rows={value.holidays}
            onPageChange={() => {}}
          />
        </>
      ))}
    </div>
  );
};

export default HolidaysParamsTable;
