/* eslint-disable @typescript-eslint/no-explicit-any*/
import {FC} from 'react';
import Modal, {ModalProps} from 'components/Modal/Modal';
import {Button} from '@mui/material';
import {useTranslation} from 'react-i18next';
import GqlFormController, {
  GqlFormControllerProps,
} from 'controllers/GqlFormController/GqlFormController';
import './AddParamModal.scss';

interface AddParamModalProps
  extends Omit<ModalProps, 'children' | 'title' | 'description'> {
  children?: Children;
  title: string;
  formProps: Omit<GqlFormControllerProps, 'children'>;
}

const AddParamModal: FC<AddParamModalProps> = ({
  onClose,
  title,
  formProps,
  ...other
}) => {
  const {t} = useTranslation();

  return (
    <Modal
      small
      className="AddParamModal"
      title={title}
      onClose={onClose}
      {...other}
    >
      <GqlFormController itemProps={{xs: 12}} {...formProps}>
        {({onSubmit}: any) => (
          <div>
            <Button
              variant="outlined"
              onClick={event => onClose && onClose(event, 'backdropClick')}
            >
              {t('leaves.AddParamModal.cancel')}
            </Button>
            <Button onClick={onSubmit()} variant="contained">
              {t('leaves.AddParamModal.submit')}
            </Button>
          </div>
        )}
      </GqlFormController>
    </Modal>
  );
};

export default AddParamModal;
