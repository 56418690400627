/* eslint-disable @typescript-eslint/no-explicit-any */
import {FC, useEffect, useState} from 'react';
import GqlFormController from 'controllers/GqlFormController/GqlFormController';
import {Button, ModalProps} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {CREATE_COUNTRY_CONFIGURATION} from 'graphql/absences/Mutations';
import withFormViewModel from 'viewComponents/CreateLeaveRequestForm/formViewModel';
import BlankCard from 'components/BlankCard/BlankCard';
import countries from 'i18n-iso-countries';

interface LeaveParamsCreateCountryFormProps
  extends Omit<ModalProps, 'children' | 'onSubmit'> {
  onSubmit: () => Promise<any>;
}

const LeaveParamsCreateCountryForm: FC<LeaveParamsCreateCountryFormProps> = ({
  onSubmit,
}) => {
  const {t} = useTranslation();
  const [countryAlpha3Codes, setCountryAlpha3Codes] = useState<string[]>([]);

  const handleOnSubmit = (mutationPromise: any) => {
    mutationPromise.then(() => {
      onSubmit();
    });
  };

  const variablesMapper = (formData: any) => {
    const request = {
      ...formData,
    };
    return request;
  };

  useEffect(() => {
    setCountryAlpha3Codes(Object.keys(countries.getAlpha3Codes()));
  }, []);

  return (
    <div
      className="LeaveParamsCreateCountryForm mt-4"
      data-testid="LeaveParamsCreateCountryForm"
    >
      <BlankCard title="Agregar país">
        <GqlFormController
          mutation={CREATE_COUNTRY_CONFIGURATION}
          variablesMapper={variablesMapper}
          className="flex flex-col"
          itemProps={{xs: 12}}
          onSubmit={handleOnSubmit}
          controls={[
            {
              name: 'countryCode',
              type: 'select',
              props: {
                label: t(
                  'leaves.CountryConfiguration.LeaveParamsCreateCountryForm.countryLabel'
                ),
                placeholder: t(
                  'leaves.CountryConfiguration.LeaveParamsCreateCountryForm.countryLabel'
                ),
                children: countryAlpha3Codes.map((code: any) => ({
                  key: code,
                  description: `${countries.getName(code, 'es')} (${code})`,
                })),
              },
            },
          ]}
        >
          {({onSubmit}: any) => (
            <Button onClick={onSubmit()} variant="contained">
              {t(
                'leaves.CountryConfiguration.LeaveParamsCreateCountryForm.submit'
              )}
            </Button>
          )}
        </GqlFormController>
      </BlankCard>
    </div>
  );
};

export default withFormViewModel()(LeaveParamsCreateCountryForm);
