import {LeavesRoutes} from 'navigation/routes';
import {FC, useEffect, useState} from 'react';
import Navbar from 'components/navbar/navbarComponent';
import {useAuth} from 'contextApi/context/auth';
import {AUTHCONST} from 'commons/auth/authConst';
import Menu from 'components/menu/Menu';
import Banner from 'components/banner/Banner';
import {NAME_ICONS} from 'commons/icons/IconConst';
import Icons from 'components/icons/Icons';
import {IMG_Banner} from 'commons/img/imgBase64';
import {getLocalStorageUserState} from 'commons/localStorage.utilities';
import {useMutation} from '@apollo/client';
import {GET_USER} from 'graphql/admin/Querys';
import Container from 'components/container/Container';
import {useLocation} from 'react-router-dom';
import {AlertContainer} from 'commons/AlertContainer';
import RoleGuard from 'commons/guards/roleGuard';
import {useTranslation} from 'react-i18next';

import './Layout.css';

const Layout: FC = ({children}) => {
  const [execLogin] = useMutation(GET_USER);
  const {logOut, userDetails} = useAuth();
  const [paloPoints, setPaloPoints] = useState<number>();
  const {t} = useTranslation();
  const userId = userDetails?.data?.id || '';

  const {pathname} = useLocation();

  const PalopointsPalowanRoutesNavbar = [
    {
      id: 1,
      Icon1: NAME_ICONS.TICKET,
      route: '/private/palowan/palopoints',
      label: t('layout.activities'),
    },
  ];

  const PalopointsAdminRoutesNavbar = [
    {
      id: 1,
      Icon1: NAME_ICONS.HOME,
      route: '/private/palowan/palopoints/admin',
      label: t('layout.quarters'),
    },
    {
      id: 2,
      Icon1: NAME_ICONS.WINNERS,
      route: '/private/palowan/palopoints/winners',
      label: t('layout.winners'),
    },
    {
      id: 3,
      Icon1: NAME_ICONS.METRIC,
      route: '/private/palowan/palopoints/metrics',
      label: t('layout.metrics'),
    },
  ];

  const ConfigAdminRoutesNavbar = [
    {
      id: 1,
      Icon1: NAME_ICONS.ENVELOPE,
      label: t('layout.requests'),
      route: `management/${LeavesRoutes.PENDING_APPROVALS}/${userId}`,
      roles: {renderForAdmin: true, renderForSupervisor: true},
    },
    {
      id: 2,
      Icon1: NAME_ICONS.EVENT,
      label: t('layout.additionalLeave'),
      route: `management/${LeavesRoutes.ADDITIONAL_LEAVES}`,
      roles: {renderForAdmin: true},
    },
    {
      id: 2,
      Icon1: NAME_ICONS.METRIC,
      label: t('layout.reports'),
      route: `management/${LeavesRoutes.REPORTS}`,
      roles: {renderForAdmin: true, renderForSupervisor: true},
    },
    {
      id: 2,
      Icon1: NAME_ICONS.SETTINGS,
      label: t('layout.settings'),
      route: `management/${LeavesRoutes.CONFIGURATION}`,
      roles: {renderForAdmin: true},
    },
  ];

  useEffect(() => {
    const email = getLocalStorageUserState(AUTHCONST.EMAIL).data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    execLogin({variables: {email: email}}).then((data: any) => {
      setPaloPoints(data.data.login.user.palopoints);
    });
  }, []);

  const handlelogOut = () => {
    logOut();
  };

  const menuItem = [
    {
      ItemMenu: (
        <>
          <div className=" flex">
            <RoleGuard renderForPalowan>
              <div className=" flex justify-center items-center mr-5">
                <p className=" font-semibold text-xl text-orange-modal ml-5">
                  {paloPoints}
                </p>
                <Icons nameIcon={NAME_ICONS.PALOPOINT} active={true} />
              </div>
            </RoleGuard>
            <div
              onClick={handlelogOut}
              className="px-3 py-2 cursor-pointer	flex items-center hover:opacity-75"
              data-testid="divBarLogOut"
            >
              <Icons nameIcon={NAME_ICONS.LOGOUT} active={true} />
            </div>
          </div>
        </>
      ),
    },
  ];

  const BannerImg = (
    <div className="flex justify-center">
      <AlertContainer>
        <img className="w-full max-h-Banner" src={IMG_Banner} alt="banner" />
      </AlertContainer>
    </div>
  );

  const isLocatedOnPalopointsModule = pathname?.includes('palopoints');
  const isLocatedOnManagementModule = pathname?.includes('management');

  return (
    <div
      className={`Layout flex ${
        isLocatedOnPalopointsModule && 'hidden-navbar'
      }`}
    >
      {isLocatedOnPalopointsModule && (
        <>
          <RoleGuard renderForPalowan hideForAdmin>
            <Navbar iconsRouteAdmin={PalopointsPalowanRoutesNavbar} />
          </RoleGuard>
          <RoleGuard renderForAdmin>
            <Navbar iconsRouteAdmin={PalopointsAdminRoutesNavbar} />
          </RoleGuard>
        </>
      )}
      {isLocatedOnManagementModule && (
        <>
          <Navbar iconsRouteAdmin={ConfigAdminRoutesNavbar} />
        </>
      )}
      <div className="relative flex flex-1 flex-col">
        <div className="flex flex-col absolute w-full">
          <Menu Menu={menuItem} />
          <Banner Banner={BannerImg} />
        </div>
        <Container>{children}</Container>
      </div>
    </div>
  );
};

export default Layout;
