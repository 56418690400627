/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import StickyHeadTable, {
  ColumnProps,
} from 'components/StickyHeadTable/StickyHeadTable';
import {useTranslation} from 'react-i18next';
import ModalController from 'controllers/ModalController';
import {Button, IconButton} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteParamModal from 'viewComponents/DeleteParamModal/DeleteParamModal';
import {ConfigurationTableProps} from 'models/configuration';
import {ADD_INABILITY, DELETE_INABILITY} from 'graphql/admin/Mutation';
import AddParamModal from 'viewComponents/AddParamModal/AddParamModal';
import GqlQueryController from 'controllers/GqlQueryController/GqlQueryController';
import {GET_CATALOGS} from 'graphql/absences/Querys';
import {Catalog} from 'models/catalog';
import {INABILITY} from 'models/leaveRequestType';
import './HealthcareParamsTable.scss';

const HealthcareParamsTable: React.FC<ConfigurationTableProps> = ({
  country,
  values,
  fetch,
}) => {
  const {t} = useTranslation();

  const renderDeleteController = (values: any) => (
    <ModalController
      trigger={({onToggleModal}) => (
        <IconButton onClick={onToggleModal}>
          <DeleteIcon />
        </IconButton>
      )}
      modal={({isModalOpen, onToggleModal}) => (
        <DeleteParamModal
          variablesMapper={() => {
            return {
              country,
              leaveType: values.type.name,
            };
          }}
          onSubmit={fetch}
          mutation={DELETE_INABILITY}
          values={values}
          onClose={onToggleModal}
          open={isModalOpen}
        />
      )}
    />
  );

  const columns: ColumnProps[] = [
    {
      id: 'type',
      label: t('leaves.HealthcareParamsTable.type'),
      render: (type: any) => type.description,
    },
    {
      id: 'numberOfDays',
      label: t('leaves.HealthcareParamsTable.days'),
    },
    {
      id: 'id',
      label: t('leaves.HealthcareParamsTable.actions'),
      align: 'right',
      render: (_: string, values: any) => renderDeleteController(values),
    },
  ];

  return (
    <div className="HealthcareParamsTable w-full flex flex-col flex-wrap">
      <div className="flex flex-row justify-end py-6">
        <GqlQueryController
          QUERY={GET_CATALOGS}
          variables={{
            type: 'LEAVE_TYPE',
            country: country,
          }}
          initialState={[] as Catalog[]}
          rootField="catalogs"
        >
          {({data: leaveTypes}) => {
            const inabilityTypes =
              (leaveTypes || []).find(
                (type: Catalog) => type.name === INABILITY
              )?.children || [];
            return (
              <ModalController
                trigger={({onToggleModal}) => (
                  <Button
                    variant="outlined"
                    onClick={() => {
                      onToggleModal();
                    }}
                  >
                    {t('leaves.HealthcareParamsTable.addButtonLabel')}
                  </Button>
                )}
                modal={({isModalOpen, onToggleModal}) => (
                  <AddParamModal
                    title={t('leaves.HealthcareParamsTable.addModalTitle')}
                    formProps={{
                      controls: [
                        {
                          name: 'leaveType',
                          type: 'select',
                          options: {
                            required: true,
                          },
                          props: {
                            label: t('leaves.HealthcareParamsTable.type'),
                            placeholder: t('common.choose_an_option'),
                            className: 'mt-2',
                            noSelection: t('common.choose_an_option'),
                            children: inabilityTypes.map(
                              (subLeaveType: any) => ({
                                key: subLeaveType.name,
                                description: subLeaveType.description,
                              })
                            ),
                          },
                        },
                        {
                          name: 'days',
                          options: {
                            required: true,
                          },
                          props: {
                            label: t('leaves.HealthcareParamsTable.days'),
                            inputProps: {type: 'number'},
                          },
                        },
                      ],
                      variablesMapper: (values: any) => {
                        return {
                          ...values,
                          country,
                          days: Number(values.days),
                        };
                      },
                      onSubmit: () => {
                        fetch();
                        onToggleModal();
                      },
                      mutation: ADD_INABILITY,
                    }}
                    onClose={onToggleModal}
                    open={isModalOpen}
                  />
                )}
              />
            );
          }}
        </GqlQueryController>
      </div>
      <StickyHeadTable
        columns={columns}
        placeholder={t('common.empty_list')}
        hidePagination
        isLoading={false}
        rows={values}
        onPageChange={() => {}}
      />
    </div>
  );
};

export default HealthcareParamsTable;
