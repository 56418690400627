import {Approval} from './approval';
import {Evidence} from './evidence';
import {Catalog} from './catalog';
import {HalfDay} from './halfDay';

export const LEAVE_STATUS_APPROVED = 'APPROVED';
export const LEAVE_STATUS_REJECTED = 'REJECTED';
export const LEAVE_STATUS_PENDING = 'PENDING';
export const LEAVE_STATUS_IN_PROCESS = 'IN_PROCESS';
export const LEAVE_STATUS_DRAFT = 'DRAFT';
export const LEAVE_STATUS_UNKNOWN = 'UNKNOWN';
export const LEAVE_STATUS_CANCELLED = 'CANCELLED';
export const LEAVE_STATUS_DELETED = 'DELETED';
export const LEAVE_STATUS_APPROVAL_CANCELLED = 'APPROVAL_CANCELLED';

export const leaveStatusArray = [
  LEAVE_STATUS_APPROVED,
  LEAVE_STATUS_REJECTED,
  LEAVE_STATUS_PENDING,
  LEAVE_STATUS_IN_PROCESS,
  LEAVE_STATUS_DRAFT,
  LEAVE_STATUS_UNKNOWN,
  LEAVE_STATUS_DELETED,
  LEAVE_STATUS_CANCELLED,
] as const;

export type LeaveStatus = typeof leaveStatusArray[number];

export const leaveStatusLabels = (status?: string): string => {
  switch (status) {
    case LEAVE_STATUS_APPROVED:
      return 'leaves.status.APPROVED';
    case LEAVE_STATUS_REJECTED:
      return 'leaves.status.REJECTED';
    case LEAVE_STATUS_PENDING:
      return 'leaves.status.PENDING';
    case LEAVE_STATUS_IN_PROCESS:
      return 'leaves.status.IN_PROCESS';
    case LEAVE_STATUS_DRAFT:
      return 'leaves.status.DRAFT';
    case LEAVE_STATUS_CANCELLED:
      return 'leaves.status.CANCELLED';
    case LEAVE_STATUS_APPROVAL_CANCELLED:
      return 'leaves.status.APPROVAL_CANCELLED';
    case LEAVE_STATUS_DELETED:
      return 'leaves.status.DELETED';
    default:
      return 'leaves.status.UNKNOWN';
  }
};

export interface LeaveRequest {
  id?: string;
  startDate: string;
  endDate: string;
  approvals?: Approval[];
  assignationType: string;
  createdAt: string;
  daysOff: number;
  evidences?: Evidence[];
  status?: LeaveStatus;
  typeOfLeave: Catalog;
  userId: string;
  userName: string;
  scheduleOptions?: HalfDay[];
}

export type LeaveRequestFilterData = {
  name?: string;
  status?: typeof leaveStatusArray[number] | '';
  leaveRequest?: DateRange;
  leavePeriod?: DateRange;
};

export interface DateRange {
  startDate: Date | null;
  endDate: Date | null;
}
