/* eslint-disable @typescript-eslint/no-explicit-any */
import {FC, useEffect, useState} from 'react';
import {useGraphqlQuery} from 'hooks/useGraphqlQuery';
import {DocumentNode} from '@apollo/client';
import LoaderWithBackdrop from 'components/LoaderWithBackdrop';
import {useLogger} from '../../hooks/useLogger';

export type GraphqlQuery = DocumentNode;

interface GqlQueryControllerChildProps {
  data: any;
  isLoading?: boolean;
  fetch: () => Promise<void>;
  setQueryVars: Function;
}

export interface GqlQueryControllerProps {
  initialState: any;
  QUERY: GraphqlQuery;
  variables?: any;
  rootField: string;
  onSuccess?: Function;
  children: (
    props: GqlQueryControllerChildProps
  ) => JSX.Element | JSX.Element[];
}

const GqlQueryController: FC<GqlQueryControllerProps> = ({
  initialState,
  QUERY,
  variables,
  children,
  rootField,
  onSuccess,
}) => {
  const logger = useLogger('GqlQueryController');
  const [queryVars, setQueryVars] = useState(variables || {});
  const {data, fetchData, isLoading} = useGraphqlQuery(
    initialState,
    QUERY,
    rootField
  );

  const fetch = async () => {
    logger.debug('fetching data', queryVars);
    if (queryVars) {
      await fetchData(queryVars);
    }
  };

  useEffect(() => {
    fetch();
  }, [queryVars]);

  useEffect(() => {
    if (onSuccess) {
      onSuccess(data);
    }
  }, [data]);

  return (
    <>
      <LoaderWithBackdrop isLoading={!!isLoading} />
      {children({data, isLoading, fetch, setQueryVars})}
    </>
  );
};
export default GqlQueryController;
