import {gql} from '@apollo/client';

export const CREATE_DINAMYCS = gql`
  mutation Mutation(
    $description: String!
    $finalDate: String!
    $mimeType: String!
    $nameimg: String!
    $receipt: String!
    $initialDate: String!
    $name: String!
    $palopoints: Float!
    $quarterId: Float!
    $typeActivity: Float!
  ) {
    CreateDinamycs(
      description: $description
      finalDate: $finalDate
      mime_type: $mimeType
      nameimg: $nameimg
      receipt: $receipt
      initialDate: $initialDate
      name: $name
      palopoints: $palopoints
      quarterId: $quarterId
      typeActivity: $typeActivity
    ) {
      message
    }
  }
`;
export const UPDATE_DINAMYCS = gql`
  mutation Mutation(
    $description: String!
    $finalDate: String!
    $mimeType: String!
    $nameimg: String!
    $receipt: String!
    $initialDate: String!
    $name: String!
    $palopoints: Float!
    $quarterId: Float!
    $typeActivity: Float!
    $activityId: Float!
  ) {
    UpdateDinamycs(
      description: $description
      finalDate: $finalDate
      mime_type: $mimeType
      nameimg: $nameimg
      receipt: $receipt
      initialDate: $initialDate
      name: $name
      palopoints: $palopoints
      quarterId: $quarterId
      typeActivity: $typeActivity
      activityID: $activityId
    ) {
      message
    }
  }
`;
export const VALIDATE_EVIDENCE = gql`
  mutation Mutation(
    $approved: Boolean!
    $evidenceId: Float!
    $reason: String!
  ) {
    ValidateEvidence(
      approved: $approved
      evidenceID: $evidenceId
      reason: $reason
    ) {
      message
    }
  }
`;

export const DELETE_HOLIDAY = gql`
  mutation DeleteHoliday($date: String!, $year: Int!, $country: String!) {
    deleteHoliday(date: $date, year: $year, country: $country) {
      year
    }
  }
`;

export const ADD_HOLIDAY = gql`
  mutation RegisterHoliday(
    $date: String!
    $year: Int!
    $country: String!
    $description: String!
  ) {
    registerHoliday(
      date: $date
      year: $year
      country: $country
      description: $description
    ) {
      year
    }
  }
`;

export const DELETE_INABILITY = gql`
  mutation DeleteInability($country: String!, $leaveType: String!) {
    deleteInability(country: $country, leaveType: $leaveType) {
      numberOfDays
    }
  }
`;

export const ADD_INABILITY = gql`
  mutation AddInability($country: String!, $leaveType: String!, $days: Float!) {
    addInability(country: $country, leaveType: $leaveType, days: $days) {
      type {
        description
      }
    }
  }
`;

export const DELETE_VACATION_RULE = gql`
  mutation DeleteVacationRule($yearThreshold: Float!, $country: String!) {
    deleteVacationRule(yearThreshold: $yearThreshold, country: $country) {
      timeInService
      vacationDays
    }
  }
`;

export const ADD_VACATION_RULE = gql`
  mutation AddVacationRule(
    $yearThreshold: Float!
    $country: String!
    $days: Float!
  ) {
    addVacationRule(
      yearThreshold: $yearThreshold
      country: $country
      days: $days
    ) {
      timeInService
    }
  }
`;
