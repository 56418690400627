/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {FC, useEffect} from 'react';
import Modal from 'components/Modal/Modal';
import {Box, Button, Grid, ModalProps} from '@mui/material';
import {useTranslation} from 'react-i18next';
import './LeaveRequestFilterModal.scss';
import OutlinedInput from 'components/OutlinedInput/OutlinedInput';
import {useForm} from 'react-hook-form';
import DropDownInput from 'components/DropDownInput';
import Checkbox from 'components/Checkbox/Checkbox';
import {
  LeaveRequestFilterData,
  leaveStatusArray,
  leaveStatusLabels,
} from 'models/leaveRequest';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';

interface LeaveRequestFilterModalProps
  extends Omit<ModalProps, 'children' | 'onSubmit'> {
  onSubmit: (data: LeaveRequestFilterData | undefined) => void;
}

interface Checkboxes {
  [key: string]: boolean;
}

const LeaveRequestFilterModal: FC<LeaveRequestFilterModalProps> = ({
  onClose,
  onSubmit: submitCallback,
  ...other
}) => {
  const {t} = useTranslation();
  const startCheckBoxesState: Checkboxes = {
    name: false,
    status: false,
    leaveRequest: false,
    leavePeriod: false,
  };
  const [checkboxes, setCheckboxes] = React.useState(startCheckBoxesState);
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: {errors},
  } = useForm<LeaveRequestFilterData>({
    defaultValues: {
      name: '',
      status: '',
      leaveRequest: {startDate: null, endDate: null},
      leavePeriod: {startDate: null, endDate: null},
    },
  });

  const getStatusOptions = () => [
    {key: '', description: '-- seleccione --'},
    ...leaveStatusArray.map(status => {
      return {key: status, description: t(leaveStatusLabels(String(status)))};
    }),
  ];

  const getValidation = (checked: boolean) => async (value: any) => {
    return (
      !!value || !checked || t('leaves.LeaveRequestFilterModal.emptyNotAllowed')
    );
  };

  const getDateValidation = (checked: boolean) => {
    return async (value: any) => {
      return (
        (!!value.startDate && !!value.endDate) ||
        !checked ||
        t('leaves.LeaveRequestFilterModal.emptyNotAllowed')
      );
    };
  };

  const clearErrors = () => {
    reset(getValues());
  };

  const onClean = () => {
    setCheckboxes(startCheckBoxesState);
    reset();
    submitCallback(undefined);
    onClose && onClose({}, 'backdropClick');
  };

  const onSubmit = (data: any) => {
    const clone = (data: any) => {
      return JSON.parse(JSON.stringify(data));
    };
    const clonedData = clone(data);
    for (const key in checkboxes) {
      if (!checkboxes[key]) {
        delete clonedData[key];
      }
    }
    submitCallback(clonedData);
    onClose && onClose({}, 'backdropClick');
  };

  const NameInput = React.memo(() => {
    return (
      <OutlinedInput
        InputProps={{
          ...register('name', {validate: getValidation(checkboxes.name)}),
        }}
      />
    );
  });

  const StatusDropDown = React.memo(() => {
    return (
      <DropDownInput
        {...register('status', {validate: getValidation(checkboxes.status)})}
        children={getStatusOptions()}
        defaultValue={getValues('status')}
        noSelection=""
      />
    );
  });

  const DateRangeInput = React.memo(
    ({
      field,
      checked,
    }: {
      field: 'leaveRequest' | 'leavePeriod';
      checked: boolean;
    }) => {
      useEffect(() => {
        register(field, {validate: getDateValidation(checked)});
      }, [checked]);

      const lastValue = getValues(field);
      return (
        <DateRangePicker
          label={''}
          defaultValue={lastValue}
          onChangeDates={(startDate: Date | null, endDate: Date | null) => {
            if (startDate && endDate) {
              setValue(field, {startDate, endDate});
            }
          }}
        />
      );
    }
  );

  const FilterFormItem = ({
    label,
    field,
    children,
  }: {
    label: string;
    field: 'name' | 'status' | 'leaveRequest' | 'leavePeriod';
    checkboxes: Checkboxes;
    setCheckboxes: (checkboxes: Checkboxes) => void;
    errors: any;
    children?: React.ReactNode;
  }) => {
    const onChangeMethod = (_event: any, checked: boolean) => {
      const checkboxesClone = {...checkboxes};
      checkboxesClone[field] = checked;
      setCheckboxes(checkboxesClone);
      clearErrors();
    };

    const checked = checkboxes[field];

    return (
      <Grid item xs={12} display="flex" flexDirection="column">
        <Checkbox
          control={<></>}
          label={label}
          CheckboxProps={{
            onChange: onChangeMethod,
            checked,
          }}
        ></Checkbox>
        {checkboxes[field] && (
          <>
            {children}
            {errors[field] && (
              <span className=" text-red-400 text-sm font-monseratt ">
                {errors[field]?.message}
              </span>
            )}
          </>
        )}
      </Grid>
    );
  };

  return (
    <Modal
      title={t('leaves.LeaveRequestFilterModal.title')}
      description={t('leaves.LeaveRequestFilterModal.description')}
      onClose={onClose}
      {...other}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box display="flex" flexDirection="column" sx={{minHeight: 520}}>
          <Grid container className="filter-form-container" spacing={2}>
            <FilterFormItem
              label="Nombre"
              field="name"
              setCheckboxes={setCheckboxes}
              checkboxes={checkboxes}
              errors={errors}
            >
              <NameInput />
            </FilterFormItem>

            <FilterFormItem
              label="Estado"
              field="status"
              setCheckboxes={setCheckboxes}
              checkboxes={checkboxes}
              errors={errors}
            >
              <StatusDropDown />
            </FilterFormItem>

            <FilterFormItem
              label="Fecha de solicitud"
              field="leaveRequest"
              setCheckboxes={setCheckboxes}
              checkboxes={checkboxes}
              errors={errors}
            >
              <DateRangeInput
                field="leaveRequest"
                checked={checkboxes.leaveRequest}
              />
            </FilterFormItem>

            <FilterFormItem
              label="Periodo de vacaciones"
              field="leavePeriod"
              setCheckboxes={setCheckboxes}
              checkboxes={checkboxes}
              errors={errors}
            >
              <DateRangeInput
                field="leavePeriod"
                checked={checkboxes.leavePeriod}
              />
            </FilterFormItem>
          </Grid>

          <Grid
            item
            xs={12}
            className={'mt-2'}
            display="flex"
            justifyContent="flex-end"
            alignItems="end"
            sx={{
              flexGrow: 1, // Allows this grid to take up remaining space
            }}
          >
            <Button
              variant="outlined"
              onClick={event => onClose && onClose(event, 'backdropClick')}
            >
              {t('leaves.LeaveRequestFilterModal.exit')}
            </Button>
            <Button variant="contained" onClick={onClean}>
              {t('leaves.LeaveRequestFilterModal.clean')}
            </Button>
            <Button variant="contained" type="submit">
              {t('leaves.LeaveRequestFilterModal.submit')}
            </Button>
          </Grid>
        </Box>
      </form>
    </Modal>
  );
};

export default LeaveRequestFilterModal;
