/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import StickyHeadTable, {
  ColumnProps,
} from 'components/StickyHeadTable/StickyHeadTable';
import {useTranslation} from 'react-i18next';
import ModalController from 'controllers/ModalController';
import {Button, IconButton} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteParamModal from 'viewComponents/DeleteParamModal/DeleteParamModal';
import {ConfigurationTableProps} from 'models/configuration';
import {ADD_VACATION_RULE, DELETE_VACATION_RULE} from 'graphql/admin/Mutation';
import './VacationDaysParamsTable.scss';
import AddParamModal from 'viewComponents/AddParamModal/AddParamModal';

const VacationDaysParamsTable: React.FC<ConfigurationTableProps> = ({
  values,
  country,
  fetch,
}) => {
  const {t} = useTranslation();
  const renderDeleteController = (values: any) => (
    <ModalController
      trigger={({onToggleModal}) => (
        <IconButton onClick={onToggleModal}>
          <DeleteIcon />
        </IconButton>
      )}
      modal={({isModalOpen, onToggleModal}) => (
        <DeleteParamModal
          variablesMapper={() => {
            return {
              country,
              yearThreshold: values.timeInService,
            };
          }}
          onSubmit={fetch}
          mutation={DELETE_VACATION_RULE}
          values={values}
          onClose={onToggleModal}
          open={isModalOpen}
        />
      )}
    />
  );

  const columns: ColumnProps[] = [
    {
      id: 'timeInService',
      label: t('leaves.VacationDaysParamsTable.years'),
    },
    {
      id: 'vacationDays',
      label: t('leaves.VacationDaysParamsTable.days'),
    },
    {
      id: 'id',
      label: t('leaves.VacationDaysParamsTable.actions'),
      align: 'right',
      render: (_, values: any) => renderDeleteController(values),
    },
  ];

  return (
    <div className="VacationDaysParamsTable w-full flex flex-col flex-wrap">
      <div className="flex flex-row justify-end py-6">
        <ModalController
          trigger={({onToggleModal}) => (
            <Button
              variant="outlined"
              onClick={() => {
                onToggleModal();
              }}
            >
              {t('leaves.VacationDaysParamsTable.addButtonLabel')}
            </Button>
          )}
          modal={({isModalOpen, onToggleModal}) => (
            <AddParamModal
              title={t('leaves.VacationDaysParamsTable.addModalTitle')}
              formProps={{
                controls: [
                  {
                    name: 'yearThreshold',
                    options: {
                      required: true,
                    },
                    props: {
                      label: t('leaves.VacationDaysParamsTable.years'),
                      inputProps: {type: 'number'},
                    },
                  },
                  {
                    name: 'days',
                    options: {
                      required: true,
                    },
                    props: {
                      label: t('leaves.VacationDaysParamsTable.days'),
                      inputProps: {type: 'number'},
                    },
                  },
                ],
                variablesMapper: (values: any) => {
                  return {
                    ...values,
                    country,
                    days: Number(values.days),
                    yearThreshold: Number(values.yearThreshold),
                  };
                },
                onSubmit: () => {
                  fetch();
                  onToggleModal();
                },
                mutation: ADD_VACATION_RULE,
              }}
              onClose={onToggleModal}
              open={isModalOpen}
            />
          )}
        />
      </div>
      <StickyHeadTable
        columns={columns}
        placeholder={t('common.empty_list')}
        hidePagination
        isLoading={false}
        rows={values}
        onPageChange={() => {}}
      />
    </div>
  );
};

export default VacationDaysParamsTable;
