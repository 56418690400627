import React, {FC, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {ColumnProps} from 'components/StickyHeadTable/StickyHeadTable';
import {GET_LEAVE_REQUEST_BY_APPROVAL} from 'graphql/absences/Querys';
import GqlTableController from 'controllers/GqlTableController/GqlTableController';
import {
  LEAVE_STATUS_APPROVED,
  LEAVE_STATUS_IN_PROCESS,
  LEAVE_STATUS_PENDING,
  LEAVE_STATUS_REJECTED,
  LeaveRequestFilterData,
  LeaveStatus,
} from 'models/leaveRequest';
import BlankCardWithTabs from 'components/BlankCardWithTabs/BlankCardWithTabs';
import {DocumentNode} from '@apollo/client';
import ViewTitle from 'viewComponents/ViewTitle/ViewTitle';
import Status from 'components/Status/Status';
import {LeavesRoutes, PrivateRoutes} from 'navigation/routes';
import RoleGuard from 'commons/guards/roleGuard';
import ModalController from 'controllers/ModalController';
import Button from 'components/button/Button';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import LeaveRequestFilterModal from 'viewComponents/LeaveRequestFilterModal/LeaveRequestFilterModal';

interface PendingApprovalsProps {
  approvalId: string;
  statusLeave?: LeaveStatus[];
  statusApproval?: LeaveStatus[];
  onlyInOrder: boolean;
  sort?: Map<string, string>;
  isAdmin?: boolean;
  filter?: Omit<LeaveRequestFilterData, 'status'> | null;
}

export const PendingApprovalsTable: FC = () => {
  const {id} = useParams<{id: string}>();
  const {t} = useTranslation();
  const [filter, setFilter] = React.useState<
    LeaveRequestFilterData | undefined
  >();
  const [selected, setSelected] = React.useState(0);
  const navigate = useNavigate();
  const columns: ColumnProps[] = [
    {
      id: 'userName',
      label: t('pendingApprovalsTable.name'),
    },
    {
      id: 'dates',
      label: t('pendingApprovalsTable.dates'),
    },
    {
      id: 'createdAt',
      label: t('pendingApprovalsTable.createdAt'),
    },
    {
      id: 'typeOfLeave',
      label: t('pendingApprovalsTable.typeOfLeave'),
      render: value => {
        return value.description || '';
      },
    },
    {
      id: 'daysOff',
      label: t('pendingApprovalsTable.numberOfDays'),
    },
    {
      id: 'status',
      label: t('pendingApprovalsTable.status'),
      render: value => {
        return <Status label={value} />;
      },
    },
    {
      id: 'assignationType',
      label: t('pendingApprovalsTable.assignation'),
    },
  ];
  // eslint-disable-next-line
  const mapData = (data: any) => ({
    dates: `${data.startDate} - ${data.endDate}`,
    ...data,
  });

  const hashFilter = (): string => {
    if (filter) {
      return JSON.stringify(filter);
    }
    return '';
  };

  const handleTabChange = (index: number) => {
    setSelected(index);
  };

  const adjustRequestDateRange = (filter: LeaveRequestFilterData) => {
    if (filter.leaveRequest?.endDate) {
      const endDate = new Date(filter.leaveRequest.endDate);
      // add a day to endDate
      endDate.setDate(endDate.getDate() + 1);
      filter.leaveRequest.endDate = endDate;
    }
  };

  const leaveRequestView = (
    document: DocumentNode,
    placeholder: string,
    withArgs: PendingApprovalsProps
  ) => {
    return (
      <GqlTableController
        rowProps={{
          // eslint-disable-next-line
          onClick: (values: any) =>
            navigate(
              `/${PrivateRoutes.PRIVATE}/leaves/${LeavesRoutes.REQUEST_DETAIL}/${values.id}`
            ),
        }}
        columns={columns}
        placeholder={placeholder}
        mapData={mapData}
        updateTrigger={hashFilter()}
        queryConfiguration={{
          query: document,
          rootField: 'leaveRequestsByApproval',
          queryVariables: (page, size) => ({
            ...withArgs,
            pageParams: {
              page: page || 1,
              size,
            },
          }),
        }}
      />
    );
  };

  const pendingApprovalView = leaveRequestView(
    GET_LEAVE_REQUEST_BY_APPROVAL,
    'No hay solicitudes pendientes',
    {
      approvalId: id || '',
      statusLeave: [LEAVE_STATUS_PENDING, LEAVE_STATUS_IN_PROCESS],
      statusApproval: [LEAVE_STATUS_PENDING],
      onlyInOrder: true,
      isAdmin: false,
      filter: null,
    }
  );

  const getHistoryView = (isAdmin: boolean) => {
    return leaveRequestView(
      GET_LEAVE_REQUEST_BY_APPROVAL,
      'No hay datos disponibles',
      {
        approvalId: id || '',
        statusApproval: [
          LEAVE_STATUS_APPROVED,
          LEAVE_STATUS_REJECTED,
          LEAVE_STATUS_PENDING,
        ],
        statusLeave: [
          LEAVE_STATUS_APPROVED,
          LEAVE_STATUS_REJECTED,
          LEAVE_STATUS_IN_PROCESS,
          LEAVE_STATUS_PENDING,
        ],
        onlyInOrder: false,
        isAdmin: isAdmin,
        filter: null,
      }
    );
  };
  const getFilteredView = (isAdmin: boolean) => {
    const filterToBeSent = {...filter};
    adjustRequestDateRange(filterToBeSent);
    delete filterToBeSent.status;
    return leaveRequestView(
      GET_LEAVE_REQUEST_BY_APPROVAL,
      'No hay datos disponibles',
      {
        approvalId: id || '',
        statusLeave: filter?.status
          ? [filter?.status]
          : [
              LEAVE_STATUS_APPROVED,
              LEAVE_STATUS_REJECTED,
              LEAVE_STATUS_IN_PROCESS,
              LEAVE_STATUS_PENDING,
            ],
        statusApproval: [
          LEAVE_STATUS_APPROVED,
          LEAVE_STATUS_REJECTED,
          LEAVE_STATUS_PENDING,
        ],
        onlyInOrder: false,
        isAdmin: isAdmin,
        filter: filterToBeSent,
      }
    );
  };

  return (
    <div className="PalowansOnBreakTable w-full flex flex-col flex-wrap pt-12">
      <ViewTitle>Solicitudes de palowans</ViewTitle>
      <RoleGuard
        getRole
        render={({isAdmin}) => {
          return (
            <>
              {selected === 1 && (
                <div className="relative">
                  <div className="absolute right-4 top-8 z-10">
                    <ModalController
                      trigger={({onToggleModal}) => (
                        <Button
                          variant={filter ? 'contained' : 'outlined'}
                          text="Filtro"
                          startIcon={<FilterAltIcon />}
                          onClick={onToggleModal}
                        />
                      )}
                      modal={({isModalOpen, onToggleModal}) => {
                        useEffect(() => {
                          setFilter(undefined);
                        }, []);
                        return (
                          <LeaveRequestFilterModal
                            onClose={onToggleModal}
                            onSubmit={data => {
                              setFilter(data);
                            }}
                            open={isModalOpen}
                          />
                        );
                      }}
                    />
                  </div>
                </div>
              )}
              <BlankCardWithTabs
                className="h-full mt-4"
                tabs={[
                  {
                    label: 'Solicitudes a aprobar',
                    onChange: handleTabChange,
                    view: pendingApprovalView,
                  },
                  {
                    label: 'Historial de solicitudes',
                    onChange: handleTabChange,
                    view: filter
                      ? getFilteredView(isAdmin)
                      : getHistoryView(isAdmin),
                  },
                ]}
              />
            </>
          );
        }}
      />
    </div>
  );
};
