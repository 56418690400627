import {
  FormControl,
  MenuItem,
  Select,
  SelectProps,
  Typography,
} from '@mui/material';
import React from 'react';

export interface DropDownItem {
  key: string | number;
  description: string;
}

export interface DropDownItemProps extends Omit<SelectProps, 'children'> {
  children: DropDownItem[];
  label?: string;
  noSelection: string;
}

const DropDownInput: React.FC<DropDownItemProps> = ({
  label,
  children,
  noSelection,
  ...other
}) => {
  return (
    <FormControl fullWidth>
      <Typography variant="subtitle2">{label}</Typography>
      <Select
        data-testid="absence-type"
        labelId="absence_type_label"
        placeholder={noSelection}
        {...other}
      >
        {children.map(item => (
          <MenuItem key={item.key} value={item.key}>
            {item.description}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DropDownInput;
